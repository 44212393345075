<template>
  <div class="contentView"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "child",
  data() {
    return {};
  },
  created() {},
  mounted() {},
};
</script>


<style lang="scss" scoped>
.contentView {
  background: url(https://mp4.uuuiti.com/nsd/bjt.jpg);
  background-size: 100% 100%;
  height: 100%;
}
</style>
