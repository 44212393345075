import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContentView from "../views/ContentView.vue"
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '思政“斗争艺术”大矩阵',
    }
  },
  {
    path: "/content/",
    name: "content",
    component: ContentView,
    meta: {
      title: '活动详情',
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach(async (to) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
});
export default router
