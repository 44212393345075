<template>
  <router-view />
</template>

<style lang="scss">
@import "./assets/css/style.scss";
#app {
  height: 100%;
}
</style>
